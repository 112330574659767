import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import PublicHeader from "./Header";
import PublicFooter from "./Footer";
// import {
//     required,
//     email,
//     FormMixin,
//     default as UiForm,
// } from "@/components/Form/Form";
// import Focus from "@/components/Focus";
export default {
  // mixins: [FormMixin],
  meta: {
    /*@V:
            title: 'Контакты'
            */
    ////!&: 2022-06-24 title: "SalesFinder - Связаться с нами",
    title: "Связаться с нами",
    meta: [{
      name: "description",
      content: "Мы всегда рады Вашим вопросам, отзывам и предложениям по работе сервиса SalesFinder"
    }, {
      name: "viewport",
      content: "width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
    }]
  },
  components: {
    PublicHeader,
    // UiForm,
    // Focus,
    PublicFooter
  },
  data() {
    const {
      user
    } = this.$store.state.user;
    return {
      showContacts: !user.external_service_id,
      active_inputs: [{
        name: "name",
        title: "Имя",
        placeholder: "Например, Сергей",
        type: "text",
        error_text: "",
        show_error: false,
        show_success: false,
        model: "",
        active: false,
        touched: false,
        // validators: [required()],
        validateOnBlur: false
      }, {
        name: "email_address",
        title: "Email",
        placeholder: "Например, mptrends@info.ru",
        type: "email",
        error_text: "",
        show_error: false,
        show_success: false,
        model: "",
        active: false,
        touched: false,
        // validators: [required(), email()],
        validateOnBlur: false
      }, {
        name: "phone_number",
        title: "Телефон (опционально)",
        placeholder: "Например, +7 495 555 55 55",
        type: "text",
        error_text: "",
        show_error: false,
        show_success: false,
        model: "",
        active: false,
        touched: false,
        validateOnBlur: false
      }, {
        name: "message",
        title: "Комментарий",
        placeholder: "Чем мы можем вам помочь?",
        type: "textarea",
        error_text: "",
        show_error: false,
        show_success: false,
        model: "",
        active: false,
        touched: false,
        // validators: [required()],
        validateOnBlur: false
      }],
      sentFeedback: null
    };
  },
  methods: {
    async onSubmit() {
      const isValid = await this.validate(true);
      if (!isValid) {
        return;
      }
      const feedback = await this.$store.dispatch("feedback/send", {
        name: this.active_inputs.find(i => i.name === "name").model.trim(),
        email_address: this.active_inputs.find(i => i.name === "email_address").model.trim().toLowerCase(),
        phone_number: this.active_inputs.find(i => i.name === "phone_number").model.trim(),
        message: this.active_inputs.find(i => i.name === "message").model.trim()
      });
      this.sentFeedback = feedback;
    },
    openCarrotquestChat() {
      window.carrotquest.open();
    }
  }
};